import React from "react"
import Layout from "../components/Layout"
import { Container, Tab, Nav } from "react-bootstrap"
import '../styles/tab_photos.css'
import Library from "../components/campus/Library"
import Laboratory from "../components/campus/Laboratory"
import Hostel from "../components/campus/Hostel"
import Transportation from "../components/campus/Transportation"
import Head from '../components/Head';




function Campus() {
    return (
        <div className='overflow-hidden '>
            <Head title="Campus"/>
            <Layout>
                <Container fluid className="px-5">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="tabContainer d-flex justify-content-center">
                        <Nav variant="pills" className="flex-row campusTabs" >
                            <Nav.Item>
                                <Nav.Link className="tab" eventKey="first" >Library</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="tab" eventKey="second">Laboratory</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="tab" eventKey="third">Hostel</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="tab" eventKey="fourth">Transportation</Nav.Link>
                            </Nav.Item>
                            
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first" className=''>
                                <Library />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second" className=''>
                                <Laboratory />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third" className=''>
                                <Hostel />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth" className=''>
                                <Transportation />
                            </Tab.Pane>
                            
                        </Tab.Content>
                    </Tab.Container>

                </Container>


            </Layout>
        </div>

    )
}

export default Campus;
